import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on, select } from '@ngrx/store';
import { GraphQLError } from 'graphql';
import { Predictor } from 'src/app/graphql/frontend-data-graphql';

import { PredictorUiActions, PredictorApiActions } from './predictor.actions';

export interface PredictorState extends EntityState<Predictor> {
  selectedId: string | null;
  searchToken: string;
  errors: readonly GraphQLError[];
}

export const predictorAdapter: EntityAdapter<Predictor> = createEntityAdapter<Predictor>({
  selectId: p => p.identifier,
  sortComparer: false
});

const state: PredictorState = predictorAdapter.getInitialState({ selectedId: null, searchToken: '', errors: [] });

export const predictorReducer = createReducer<PredictorState>(
  state,
  on(PredictorUiActions.select, (state, { identifier }): PredictorState => {
    return { ...state, selectedId: identifier };
  }),
  on(PredictorUiActions.add, (state): PredictorState => {
    return { ...state, selectedId: null };
  }),
  on(PredictorApiActions.findAllSucceeded, (state, { items, q }): PredictorState => {
    return predictorAdapter.addMany(items, { ...state, searchToken: q, errors: [] });
  }),
  on(PredictorApiActions.createSucceeded, PredictorApiActions.updateOneSucceeded, (state, { identifier }): PredictorState => {
    return { ...state, selectedId: identifier, errors: [] };
  }),
  on(PredictorApiActions.created, (state, { item }): PredictorState => {
    return predictorAdapter.addOne(item, { ...state });
  }),
  on(PredictorApiActions.updatedOne, (state, { item }): PredictorState => {
    return predictorAdapter.setOne(item, { ...state });
  }),
  on(PredictorApiActions.deleteOneSucceeded, (state, { identifier }): PredictorState => {
    return { ...state, selectedId: null, errors: [] };
  }),
  on(PredictorApiActions.deletedOne, (state, { identifier }): PredictorState => {
    return predictorAdapter.removeOne(identifier, { ...state });
  }),
  on(PredictorApiActions.requestFailed, (state, { errors }): PredictorState => {
    return predictorAdapter.removeAll({ ...state, selectedId: null, errors });
  })
);
