import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { GraphQLError } from 'graphql';
import { CreatePredictorDto, Predictor, PredictorFilter, UpdatePredictorDto } from 'src/app/graphql/frontend-data-graphql';

export const PredictorUiActions = createActionGroup({
  source: 'Predictor UI',
  events: {
    Select: props<{ identifier: string | null }>(),
    Add: emptyProps(),
    FindAllTriggered: props<{ filter: PredictorFilter; q: string }>(),
    CreateTriggered: props<{ input: CreatePredictorDto }>(),
    UpdateOneTriggered: props<{ identifier: string; update: UpdatePredictorDto }>(),
    DeleteOneTriggered: props<{ identifier: string }>()
  }
});

export const PredictorApiActions = createActionGroup({
  source: 'Predictor API',
  events: {
    FindAllSucceeded: props<{ items: Predictor[]; q: string }>(),
    CreateSucceeded: props<{ identifier: string }>(),
    Created: props<{ item: Predictor }>(),
    UpdateOneSucceeded: props<{ identifier: string }>(),
    UpdatedOne: props<{ item: Predictor }>(),
    DeleteOneSucceeded: props<{ identifier: string }>(),
    DeletedOne: props<{ identifier: string }>(),
    RequestFailed: props<{ errors: readonly GraphQLError[] }>()
  }
});
